/* contact starts*/

.contact-section{
    /* height: 100vh; */
    height: 100%;
    width: 100vw; 
   text-align: center;
   background-color: #f9e3e3;
   background-image: 
       radial-gradient(at 47% 33%, hsl(180.00, 18%, 94%) 0, transparent 59%), 
       radial-gradient(at 82% 65%, hsl(0.00, 91%, 85%) 0, transparent 55%);

   
  }
  .contact-section h2{
    text-align: center;
    font-size: 30px;
    margin-top: 50px;
    font-weight: bold;
    
  
  }
  .contact-info{
    margin-top: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
  
  }
  .contact-card i{
    color: var(--secondary);
    

  }
  .contact-card i:hover{
    color: white;

  }
  .contact-card {
    box-shadow: 0 0 25px rgb(144, 142, 142);
    margin: 20px 20px;
    padding: 50px 50px;
    border-radius: 10px;
    backdrop-filter: blur(14px) saturate(157%);
    -webkit-backdrop-filter: blur(14px) saturate(157%);
    background-color: rgba(255, 255, 255, 0.63);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);


  }
  .contact-card:hover, .contact-card:hover h3{
    background-color: var(--secondary);
    color: white;
  
  }

  /* form css */
  .form-section{
    padding: 100px 100px;
    width: 100%;
    text-align: center;
    padding-bottom: 50px;
  }
  .contact-title{
    color: var(--secondary);
    font-size: 35px;
  }
  .name, .phone-email{
    display: flex;
    align-items: center;
    justify-content: center;
     padding: 10px;
     margin: 10px 10px;
  }
  
  input[type=text],input[type=number],input[type=email],input[type=submit],textarea{
    border: 1px solid var(--secondary);
    background-color:var(--bg-color) ;
    color: var(--secondary);
    padding: 10px 40px;
    border-radius: .2rem;
    margin-left: 20px;
    width: 70%;
   
  }
  input::placeholder, textarea::placeholder{
    font-size: 15px;
    text-align: center;
    color: var(--bg-color);
  }
  input:focus, textarea:focus{
    background-color:var(--secondary) ; 
    outline: none;
    color: var(--bg-color);
  }
  .submitBtn{
    margin: 10px 10px;
    outline: 2px solid var(--secondary-color);
    color:var(--dark-navy);
    text-transform: uppercase;
  }
  @media only screen and (max-width: 500px){
    .contact-info, .contact-card{
      padding: 20px 20px;
      
    }
    
  }

  @media only screen and (max-width: 900px){
    .contact-section {
      margin: 0;
      height: 1200px;
      width: 100vw;
      /* overflow-y: hidden; */
  }
    .contact-info, .contact-card{
      display: grid;
      grid-template-rows: repeat(3,1fr);
      margin-bottom: 50px;
      
    }
    .contact-section{
      margin: 0;
    }
  
  }
  @media only screen and (max-width: 550px){
    
    input[type=text],input[type=number],input[type=email],input[type=submit],textarea{
     padding: 1px 1px ;
     text-align: center;
    }
  }
  @media only screen and (max-width: 420px){

    .contact-title{
      font-size: 25px;
    }

  .form-section{
    /* padding: 100px 100px; */
    width: 100%;
    text-align: center;
    padding-bottom: 50px;
  }
  input[type=text],input[type=number],input[type=email],input[type=submit],textarea{
  margin-bottom: 10px;
  }
  .name, .phone-email{
    display: grid;
     padding: 10px;
     margin-bottom: 10px;
  }
}
  