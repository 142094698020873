
.hero_section{
    height: 100vh;
    width: 100%;
    background-image: url('../assets/bg4.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-animation: changeImg 25s infinite linear alternate; /* Safari 4+ */
    -moz-animation:    changeImg 25s infinite linear alternate; /* Fx 5+ */
    -o-animation:      changeImg 25s infinite linear alternate; /* Opera 12+ */
    animation:         changeImg 25s infinite linear alternate; /* IE 10+, Fx 29+ */
   
    opacity: .9;
  }
  /* The Keyframes*/
@keyframes changeImg {

  25% {
    background-image:  url('../assets/bg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .8;
 
    
  }
  50% {
    background-image:  url('../assets/bg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .9;
  }
  
  75%{
    background-image:  url('../assets/bg5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .8;
  }
}
 
  .hero_section .hero_content h1{
    padding-top: 300px;
    color: var(--secondary);
    text-transform: capitalize;
    animation: 3s slide-left;
  }
  .hero_section .hero_content p{
    animation: 3s slide-right;
    color: white;
    font-family: 'Lobster', cursive;
  }


        
/* .arrow {
  width: 15px;
  height: 15px;
  border: 3px solid var(--secondary);
  border-left: 0;
  border-top: 0;
  margin-top: 200px;
  margin-left: 500px;
  animation: up-down 6s ease-in-out infinite;
} */

/* @keyframes up-down{
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
} */