/* footer */
.footer{
  width: 100vw;
 
    background-color: var(--secondary);
    color: white;
    padding: 10px 5px;
    font-size: 20px;
    text-transform: capitalize;
    border-radius:5px;
  }
  .footer_menu li{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  }
  .footer_menu li a{
    list-style: none;
    text-decoration: none;
    color: white;
  
  }
  /* Media Query for Mobile Devices */
  @media (max-width: 480px) {
    
    .footer{
      text-align: center;
    }
          
  }
    
  /* Media Query for low resolution  Tablets, ipads */
  @media (min-width: 481px) and (max-width: 767px) {
     
  }
    
  /* Media Query for Tablets ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px){
     
  }
    
  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px){
      
  }
    
  /* Media Query for Large screens */
  @media (min-width: 1281px) {
      
  }