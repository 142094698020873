@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.navbar{
  position: fixed;
  width: 100%;
  height: 70px;
  padding: 20px;
  background-color: var(--secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
 
}

.navbar .logo{
  color: var(--primary);
  font-size: 30px;
  cursor: pointer;
  font-family: 'Lobster', cursive;
}


.navbar .links{
  list-style: none;
  display: flex;
  color: var(--primary);
  cursor: pointer;
}

.navbar .links li{
  margin: 0 15px;
  font-size: 26px;
  color: white;
 text-transform: capitalize;
 font-family: 'Lobster', cursive;
}
.navbar .links li:hover{
  text-transform: uppercase;
}

.navbar .toggle-icon{
  display: none
}


@media(max-width: 280px){
  .navbar .logo{
    font-size: 22px;
  }

}
@media(max-width: 768px){
  .navbar{
    position: relative;
    transition: 0.5s all ease-in-out;
  }

  .navbar.expanded{
    height: 60vh;
  }

  .navbar.expanded .links{
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .navbar.expanded .links li{
    margin: 15px 0;
    font-size: 24px;
    /* border-bottom: 2px solid white; */
  }

  .navbar .logo{
    position: absolute;
    top: 23px;
    left: 20px;
  }

  .navbar .toggle-icon{
    display: block;
    color: #D9D2C3;
    position: absolute;
    top: 28px;
    right: 20px;
  }

  .navbar .links{
    position: absolute;
    top: -300px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .navbar .links li{
    margin: 15px 0;
    font-size: 24px;
  }
}