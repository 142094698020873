/* service section */

.services{
    width: 100%;
   height: 100%;
    min-height: 100vh;
    margin-top: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin:  0 auto;

    background-image:  url('../assets/aboutbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    
  }

  .services h2{
    text-align: center;
    font-size: 30px;
    margin-top: 100px;
    font-weight: bold;
    display: inline-flex; 
    position:relative;
  
  }
   .services h2::after{
    content: "";
    position: absolute;
    background-color: var(--secondary);
    height: 4px;
    bottom:-10px;
    left:0;  /* match width of text and padding */
    right: 0;   /*  match width of text and padding */  
  
  }
  .services .box-container{    
    display:grid;
    grid-template-columns: repeat(3, 1fr);

    
   
   
}

.services .box-container .box{
    width:20rem;
    height: 20rem;
    background: var(--primary-bg-color);
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    margin:1rem;
    padding:1rem;
    text-align: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
}
.services .box-container .box img{
  width: 200px;
  height: 200px;
  margin: 2rem;
  border-radius: 6px;
  box-shadow:  0 16px 32px -16px rgb(7 29 43 / 16%);
}
.services .box-container .box .number{
    position: absolute;
    top:1.5rem; left: 2rem;
    font-size: 1.5rem;
    color: var(--primary-text-color);

}
.services .box-container .box h3{
    font-size: 1.5rem;
    color: var(--primary);
}
.services .box-container .box p{
    font-size: 1.2rem;
    color: var(--primary-text-color);
    margin: 2rem;
    padding: 1rem;
}
.services .box-container .box::before{
    content:'';
    position: absolute;
    top: 0;left: 0;
    height: 100%;
    width: 100%;
    background: var(--primary);
    z-index: -1;
    clip-path: circle(25% at 0 0);
    opacity:.2 ;
    transition: .3s;
}
.services .box-container .box:hover:before{
    clip-path: circle(100%);
}
/*  edia query */
@media screen and (max-width: 375px) {
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(100px, 1fr);
       
    }
}

@media (min-width: 360px) and (max-width: 414px){
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(1, 1fr); 
    }
    .services .box-container .box{ 
        margin-left: 20px;
    }
    
 
}

@media (max-width: 480px) {
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(100px, 1fr);
       
    }
}
@media(min-width: 481px) and (max-width: 750px) {
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    
    }
    .services .box-container .box{
        width:20rem;
        height: 20rem;
    }
    .services .box-container .box img{
        width: 200px;
        height: 200px;
       margin-top: 30px;
    }
   
 
}
/* Media Query for low resolution  Tablets, ipads */
@media(min-width: 651px) and (max-width: 767px) {
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    
    }  
 
}

/* Media Query for Tablets ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(2, 1fr);   
    }
    .services .box-container .box{ 
        margin-left: 20px;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
    .services .box-container{    
        display:grid;
        grid-template-columns: repeat(3, 1fr);
       
    }
    .services .box-container .box{
       
        margin-left: 20px;
    }
  
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  
}